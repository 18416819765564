import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import {
  darkGrey,
  lightGrey,
  accent,
  darkGreyAlt,
} from "../collections/SdColors";
import GrowingIcon from "./GrowingIcon";
import { Loader } from "../styled/StyledLoadingIndicator";
import GrowingBox from "./GrowingBox";
import StrapiRichText from "./StrapiRichText";
const flipAnimation = keyframes`
  from {
    transform: rotateY(0deg);
    transform: height(0%);
  }
  to {
    transform: rotateY(180deg);
    transform: height(100%);
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  perspective: 1000px;
  transition: transform 0.6s ease-in-out, height 0.6s ease-in-out;

  border-radius: 8px;
  overflow-y: scroll;
  /* WebKit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
`;

const CardInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transform-style: preserve-3d;
  transition: transform 1s;
  transform: ${(props) =>
    props.isFlipped ? "rotateY(180deg); " : "rotateY(0deg)"};
  animation: ${(props) => props.isFlipped && flipAnimation} 0.295s;
`;

const CardFrontBack = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const CardFront = styled(CardFrontBack)`
  position: relative;
  background-color: ${darkGrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 98%;
  opacity: ${(props) => (props.isContentVis ? "1" : "0")};
  background-color: ${darkGrey};
  border-radius: 8px;
`;
const CardBack = styled(CardFrontBack)`
  height: 100%;
  width: 98%;
  background-color: ${darkGrey};
  transform: rotateY(180deg);
  border-radius: 8px;
`;

const LongTextFlip = ({
  keyProp,
  iconSrc,
  title,
  text,

  onMouseEnter,
  onMouseLeave,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isContentVis, setIsContentVis] = useState(false);
  const [richTextContent, setRichTextContent] = useState(
    '<p style="color: red;"></p>'
  );
  const cardWrapperRef = useRef(null);
  const handleRichTextChange = (content) => {
    setRichTextContent(content);
  };
  useEffect(() => {
    setIsFlipped(true);
    setTimeout(() => {
      setIsFlipped(false);
      setIsContentVis(true);
    }, 1350);
  }, [keyProp]);

  return (
    <CardWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => {
        onMouseLeave();
        // Scroll back to the top when the mouse leaves
        cardWrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }}
      ref={cardWrapperRef}
    >
      <CardInner isFlipped={isFlipped}>
        <CardFront>
          <CardContent isContentVis={isContentVis}>
            <StrapiRichText content={text} onChange={handleRichTextChange} />
          </CardContent>
        </CardFront>

        <CardBack></CardBack>
      </CardInner>
    </CardWrapper>
  );
};

export default LongTextFlip;
