import React, { useState, useEffect } from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
  margin-top: 0px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  display: flex;
  transition: transform 2s ease-out;
  width: ${(props) => props.totalImages * 100}%;
  transform: translateX(
    -${(props) => (props.activeIndex / props.totalImages) * 100}%
  );
`;
// " bottom: 65px;" ist ein provisorischer fix
const Image = styled.img`
  bottom: 65px;
  position: relative;
  width: ${(props) => 100 / props.totalImages}%;
  height: 100%;
  object-fit: contain;
  opacity: ${(props) => (props.isActive ? "1" : "0.8")};
  transition: opacity 1s ease-out;
`;

const ImageSlider = ({ gallery, activeIndex, setActiveIndex }) => {
  const totalImages = gallery.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % totalImages);
    }, 5000);

    return () => clearInterval(interval);
  }, [totalImages, setActiveIndex]);

  return (
    <SliderContainer>
      <ImageWrapper totalImages={totalImages} activeIndex={activeIndex}>
        {gallery.map((image, index) => (
          <Image
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            isActive={index === activeIndex}
            totalImages={totalImages}
          />
        ))}
      </ImageWrapper>
    </SliderContainer>
  );
};

export default ImageSlider;
