// ImageHolder.js

import React from "react";
import styled from "styled-components";

const ImageHolderWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageStyled = styled.img`
  position: relative;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  object-fit: fill;
  border-radius: 8px;
`;

const ImageHolder = ({ src, alt, width, height, style, key }) => {
  return (
    <ImageHolderWrapper width={width} height={height} style={style}>
      <ImageStyled key={key} src={src} alt={alt} />
    </ImageHolderWrapper>
  );
};

export default ImageHolder;
