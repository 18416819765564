import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { darkGrey, lightGrey } from "./components/collections/SdColors";
import ThreeScene from "./components/ThreeScene";
import ContentHolder from "./components/ContentHolder";
import LoadingIndicator from "./components/widgets/LoadingIndicator";
const GlobalStyle = createGlobalStyle`
  .page-enter {
    opacity: 0.01;
  }
  .page-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .page-exit {
    opacity: 1;
  }
  .page-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${darkGrey};
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.ismodelloaded ? "0" : "100vh")};
  width: 100vw;
  opacity: ${(props) => (props.ismodelloaded ? "0" : "1")};
  transition: height 0s ease-out 1.5s, opacity 0.295s ease-out; /* Add a delay of 0.2s for height */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const ContentHolderDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  color: ${lightGrey};
  border: 25px solid black;
  border-radius: 35px;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 95vw;
  margin: auto;
  top: ${(props) => (props.animate ? "22px" : "500px")};
  transition: top 1.3s ease-out;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 850px) {
    height: auto;
    width: 100vw;
    top: ${(props) => (props.animate ? "0px" : "0px")};
    border: none;
  }
`;

const Home = () => <div>Home Content</div>;
const About = () => <div>About Content</div>;
const Services = () => <div>Services Content</div>;
const Contact = () => <div>Contact Content</div>;

const Button = styled(Link)`
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;

  &:hover {
    background-color: #ddd;
    color: black;
  }
`;

const App = () => {
  const modelPath = "models/monitor.glb";
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [animateMonitorUp, setAnimateMonitorUp] = useState(false);
  const [startFade, setStartFade] = useState(false);

  const handleModelLoaded = (loaded) => {
    setIsModelLoaded(loaded);
    setStartFade(true);
  };

  useEffect(() => {
    let fadeOutTimer;
    let monitorTimer;
    if (!isModelLoaded) {
      fadeOutTimer = setTimeout(() => {
        setIsModelLoaded(true);
      }, 1500);
    }
    monitorTimer = setTimeout(() => {
      setAnimateMonitorUp(false);
    }, 2000);
    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(monitorTimer);
    };
  }, [isModelLoaded, animateMonitorUp]);

  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route
          path="/"
          element={
            <ContentHolderDiv animate={isModelLoaded}>
              <ContentHolder caseValue={"portfol"} />
            </ContentHolderDiv>
          }
        />
        <Route
          path="/imprint"
          element={
            <ContentHolderDiv animate={isModelLoaded}>
              <ContentHolder caseValue={"imprint"} />
            </ContentHolderDiv>
          }
        />
        <Route
          path="/about"
          element={
            <ContentHolderDiv animate={isModelLoaded}>
              <ContentHolder caseValue={"about"} />
            </ContentHolderDiv>
          }
        />
        {/*        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />*/}
      </Routes>

      <LoadingIndicatorContainer ismodelloaded={isModelLoaded}>
        {!startFade && <LoadingIndicator />}
      </LoadingIndicatorContainer>
    </Router>
  );
};

export default App;
