import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { API_URL } from "../../config";
import ImageSlider from "../widgets/ImageSlider";
import { darkGrey, lightGrey, darkGreyAlt } from "../collections/SdColors";
import Card from "../widgets/Card";
import CarouselButtons from "../widgets/CarouselButtons";
import LongTextFlip from "../widgets/LongTextFlip";
import { TextLink } from "../styled/StyledCompGlobal";
import StackList from "../widgets/StackList";
import {
  CarouselContainer,
  Cardholder,
  LongTextHolder,
  WebsiteLinkHolder,
  ImageContainer,
} from "../styled/StyledPortfolio";
import { MenuHolder } from "../styled/StyledCarouselMenu";
import { fetchPortOwData } from "../../strapi";

const PagePortfolioOw = () => {
  const apiUrlWebPort = `${API_URL}/api/website-portfolios?populate=*`;
  const apiUrlPluginsPort = `${API_URL}/api/plugin-portfolios?populate=*`;
  const apiUrlAppsPort = `${API_URL}/api/app-portfolios/?populate=*`;
  const [dataWebPort, setWebPortData] = useState([]);
  const [dataPluginsPort, setPluginsPortData] = useState([]);
  const [DataAppsPort, setToolsPortData] = useState([]);
  const [visibleData, setVisibleData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeButtonIndexWeb, setActiveButtonIndexWeb] = useState(0);
  const [activeButtonIndexPlugins, setActiveButtonIndexPlugins] = useState();
  const [cardKey, setCardKey] = useState(Date.now());
  const [isHovered, setIsHovered] = useState(null);
  const [isRichTextHovered, setIsRichTextHovered] = useState(false);

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [parentIsFlipped, setParentIsFlipped] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Use the apiUrl from the props or any other source
    const DataWebPort = await fetchPortOwData(apiUrlWebPort);
    const DataPluginsPort = await fetchPortOwData(apiUrlPluginsPort);
    const DataAppsPort = await fetchPortOwData(apiUrlAppsPort);

    setWebPortData(DataWebPort);
    setPluginsPortData(DataPluginsPort);
    setToolsPortData(DataAppsPort);

    if (DataWebPort.length > 0) {
      setVisibleData(DataWebPort[0]);
    }

    setIsLoading(false);
  };

  const handleButtonClickWeb = (item, index) => {
    setVisibleData(item);
    setActiveIndex(0);
    setActiveButtonIndexWeb(index);
    setActiveButtonIndexPlugins();
    setCardKey(Date.now());
    console.log(parentIsFlipped);
  };

  const handleButtonClickPlugins = (item, index) => {
    setVisibleData(item);
    setActiveIndex(0);
    setActiveButtonIndexPlugins(index);
    setActiveButtonIndexWeb();
    setCardKey(Date.now());
    console.log(parentIsFlipped);
  };

  return (
    <CarouselContainer>
      {!isLoading && (
        <WebsiteLinkHolder>
          <TextLink
            color={lightGrey}
            hoverColor={darkGrey}
            href={visibleData.websiteURL}
            target="_blank"
          >
            Webseite Besuchen
          </TextLink>
        </WebsiteLinkHolder>
      )}
      {!isLoading && (
        <StackList
          imageUrls={visibleData.stackIcons}
          tooltipTexts={visibleData.stackIcons.map((icon) => icon.name)}
          linkUrls={visibleData.stackIcons.map((icon) => icon.link)}
          width={"auto"}
          height={"6%"}
          bottom={"0%"}
          left={"15%"}
        />
      )}
      <MenuHolder>
        <CarouselButtons
          data={dataWebPort}
          handleButtonClick={handleButtonClickWeb}
          activeButtonIndex={activeButtonIndexWeb}
          setIsHovered={setIsHovered}
          title="Webseiten"
        />
        <CarouselButtons
          data={DataAppsPort}
          handleButtonClick={handleButtonClickPlugins}
          activeButtonIndex={activeButtonIndexPlugins}
          setIsHovered={setIsHovered}
          title="Apps"
        />
        <CarouselButtons
          data={dataPluginsPort}
          handleButtonClick={handleButtonClickPlugins}
          activeButtonIndex={activeButtonIndexPlugins}
          setIsHovered={setIsHovered}
          title="Wordpress Plugins"
        />
      </MenuHolder>

      <ImageContainer>
        {visibleData && (
          <ImageSlider
            gallery={visibleData.gallery}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </ImageContainer>
      <Cardholder>
        {!isLoading && (
          <Card
            key={cardKey}
            iconSrc={visibleData.iconUrl}
            title={visibleData.name}
            text={visibleData.title}
          />
        )}
      </Cardholder>
      <LongTextHolder isRichTextHovered={isRichTextHovered}>
        {!isLoading && (
          <LongTextFlip
            isFlippedProp={isRichTextHovered}
            key={cardKey}
            text={
              isRichTextHovered
                ? visibleData.desc
                : "Weitere Informationen über dieses Projekt..."
            }
            onMouseEnter={() => {
              setIsRichTextHovered(true);
              console.log("Hovered");
            }}
            onMouseLeave={() => {
              const wasHovered = isRichTextHovered;
              setIsRichTextHovered(false);
              if (!wasHovered) {
                window.scrollTo({ top: 0, behavior: "smooth" });
                console.log("Not Hovered");
              }
            }}
          />
        )}
      </LongTextHolder>
    </CarouselContainer>
  );
};

export default PagePortfolioOw;
