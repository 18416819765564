import styled from "styled-components";
import { darkGrey, lightGrey } from "../collections/SdColors";

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${darkGrey};
  height: 100%;
  position: relative;
  overflow: hidden;
  position: relative; /* Added position relative to serve as a reference for absolute positioning */
`;
export const Cardholder = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  position: absolute;
  bottom: 20%;
  right: 24px;
  overflow: show;
  border-radius: 8px;
`;

export const LongTextHolder = styled.div`
  display: flex;
  border-top: 1px solid ${lightGrey};
  border-right: 1px solid ${lightGrey};
  flex-direction: column;
  height: ${(props) => (props.isRichTextHovered ? "51%" : "7%")};
  background-color: ${darkGrey};
  position: absolute;
  bottom: 10.2%;
  left: 15%;
  right: 45%;
  overflow: show;
  border-radius: 0px 8px 0 5px;
  transition: height 0.295s ease-in-out;
  z-index: 0;
`;

export const WebsiteLinkHolder = styled.div`
  display: flex;
  padding: 8px;
  align-content: center;
  border-top: 1px solid ${lightGrey};
  border-right: 1px solid ${lightGrey};
  border-left: 1px solid ${lightGrey};
  flex-direction: row;
  background-color: ${darkGrey};
  position: absolute;
  bottom: 0%;
  width: auto;
  height: 5%;
  right: 5%;
  overflow: show;
  border-radius: 8px 8px 0 0;
  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;

  &:hover {
    background-color: ${lightGrey};
    height: 6%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${darkGrey};
  height: 88%;
  width: 85%;
  position: absolute;
  top: 12px;
  right: 0px;
  overflow: hidden;
  border-top: 2.5px solid ${lightGrey};
  border-bottom: 2.5px solid ${lightGrey};
  border-left: 2.5px solid ${lightGrey};
  border-radius: 8px 0px 0px 8px;
`;

//Mobile:

export const CarouselContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${darkGrey};
  height: 100%;

  position: relative;
  overflow: hidden;
`;
export const CardholderMobile = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  position: relative;
  bottom: 0%;
  right: 0px;
  overflow: show;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
`;

export const LongTextHolderMobile = styled.div`
  display: flex;
  top: 0px;
  padding: 12px;
  flex-direction: column;
  height: ${(props) => (props.isRichTextHovered ? "51%" : "7%")};
  background-color: transparent;
  position: relative;
  transition: height 0.295s ease-in-out;
  z-index: 0;
`;

export const WebsiteLinkHolderMobile = styled.div`
  display: flex;
  padding: 8px;
  align-content: center;
  border-top: 1px solid ${lightGrey};
  border-right: 1px solid ${lightGrey};
  border-left: 1px solid ${lightGrey};
  flex-direction: row;
  background-color: ${darkGrey};
  position: relative;
  bottom: 0%;
  width: auto;
  height: 5%;
  right: 0%;
  align-items: center;
  justify-content: center;
  overflow: show;
  border-radius: 8px 8px 0 0;
  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;

  &:hover {
    background-color: ${lightGrey};
    height: 6%;
  }
`;

export const ImageContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${darkGrey};
  height: auto;
  width: 100%;
  position: relative;
  top: 0px;
  right: 0px;
  overflow: hidden;
  border-top: 2.5px solid ${lightGrey};
  border-bottom: 2.5px solid ${lightGrey};
  border-radius: 0px 0px 0px 0px;
`;
