// api.js
import { STRAPI_URL } from "./config";

export const fetchPortOwData = async (apiUrl) => {
  try {
    const response = await fetch(apiUrl);
    const result = await response.json();

    if (Array.isArray(result.data)) {
      const transformedData = result.data.map((item) => ({
        id: item.id,
        name: item.attributes.Name,
        title: item.attributes.Beschreibungstitel,
        desc: item.attributes.Beschreibung,
        iconUrl: STRAPI_URL + item.attributes.Icon.data.attributes.url,
        websiteURL: item.attributes.Link,
        thumbnailUrl:
          STRAPI_URL + item.attributes.Thumbnail.data.attributes.url,
        gallery: item.attributes.Gallery.data.map(
          (galleryItem) => STRAPI_URL + galleryItem.attributes.url
        ),
        stackIcons: (item.attributes.stacks.data || []).map((stackItem) => ({
          name: stackItem.attributes.Name || "",
          link: stackItem.attributes.Link || "",
          logoUrl: STRAPI_URL + stackItem.attributes.LogoLink || "",
        })),
      }));

      return transformedData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching Portfolio data:", error);
    return [];
  }
};

export const fetchImprintData = async (apiUrl) => {
  try {
    const response = await fetch(apiUrl);
    const result = await response.json();

    if (result.data) {
      const item = result.data;

      const transformedData = {
        id: item.id,
        personalName: item.attributes.Vertreter,
        firmName: item.attributes.Firma,
        firm: item.attributes.Unternehmen,
        email: item.attributes.Email,
        phone: item.attributes.Telefon,
        ustid: item.attributes.UStIdNr,
        street: item.attributes.Street,
        postCode: item.attributes.PostCode,
        state: item.attributes.State,
      };

      return transformedData;
    } else {
      console.error("API response did not contain expected data:", result);
      return null;
    }
  } catch (error) {
    console.error("Error fetching Imprint data:", error);
    return null;
  }
};

export const fetchAboutData = async (apiUrl) => {
  try {
    const response = await fetch(apiUrl);
    const result = await response.json();

    if (Array.isArray(result.data)) {
      const transformedData = result.data.map((item) => ({
        id: item.id,
        name: item.attributes.Name,
        position: item.attributes.Position,
        email: item.attributes.Email,
        phone: item.attributes.Telefon,
        aboutText: item.attributes.About,
        imageURL: STRAPI_URL + item.attributes.Bild.data.attributes.url,
      }));
      return transformedData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching About data:", error);
    return [];
  }
};
