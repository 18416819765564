import React from "react";
import styled, { keyframes } from "styled-components";

// Define a keyframe animation
const growAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const ImageHolderWrapper = styled.div`
  position: relative;
  top: ${(props) => props.top || "0px"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  animation: ${growAnimation} 3s ease-out; // Set the animation on the wrapper
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`;

const ImageStyled = styled.img`
  position: relative;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  object-fit: fill;
  border-radius: 8px;
`;

const GrowingIcon = ({ src, alt, width, height, style, top, key }) => {
  return (
    <ImageHolderWrapper width={width} height={height} style={style} top={top}>
      <ImageStyled key={key} src={src} alt={alt} />
    </ImageHolderWrapper>
  );
};

export default GrowingIcon;
