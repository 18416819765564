import styled from "styled-components";
import {
  darkGrey,
  lightGrey,
  accent,
  linkColor,
} from "../collections/SdColors";

export const View = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${darkGrey};
`;

export const ContentHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: 75%;
  background-color: ${darkGrey};
`;

export const ContentPhoto = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 75%;
  padding: 8px;
  border-radius: 8px;
  align-items: center;
  background-color: ${lightGrey};
`;
export const ContentName = styled.div`
  position: absolute;
  display: flex;
  bottom: 50px;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid ${darkGrey};
  background-color: ${lightGrey};
  justify-content: center;
  align-items: center;
`;

export const NameText = styled.a`
  color: ${darkGrey};
  font-size: 24px;
  text-decoration: none;
  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;
`;
export const PosText = styled.a`
  color: ${darkGrey};
  font-size: 20px;
  text-decoration: none;

  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;
`;
export const ContentPos = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: ${lightGrey};
  border-radius: 0 0 5px 5px;
  justify-content: center;
  align-items: center;
`;

export const PhotoImg = styled.div.attrs((imageSrc) => ({}))`
  width: 75%;
  height: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  border: 2px solid ${accent};
  color: ${lightGrey};
  background-image: url(${(props) => (props.imageSrc ? props.imageSrc : null)});
`;

export const ContentAboutText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 10%;
  width: 55%;
  height: auto;
  border-radius: 8px;
  background-color: ${darkGrey};
  overflow: auto;
`;

export const ContentPhoneEmail = styled.div`
  position: relative;
  display: flex;
  border-top: 2px solid ${darkGrey};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  top: calc(100% - 50px);
  width: 100q%;
  height: 50px;
`;

export const TextLink = styled.a.attrs((color, size, hoverColor) => ({}))`
  color: ${(props) => (props.color ? props.color : linkColor)};
  font-size: 24px;
  text-decoration: none;

  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;

  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : lightGrey)};
  }
`;

export const AboutText = styled.a`
  color: ${lightGrey};
  font-size: 24px;
  text-decoration: none;
  padding: 8px;
  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;
`;
