// styled.js
import styled, { keyframes } from "styled-components";
import { darkGrey, lightGrey } from "../collections/SdColors";
import { ColorKeyframeTrack } from "three";
export const OverlayContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
`;

export const LoadingInvisibleDiv = styled.div`
  position: absolute;
  background-color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
`;

export const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingAnimation = styled.div`
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #3498db; /* Blue border for animation */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite; /* Animation properties */
`;

export const StyledContentHolderContainer = styled.div`
  background-color: ${lightGrey};
  color: ${darkGrey};
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

export const StyledContent = styled.div`
  /* Add any additional styles for the content here */
`;

export const StyledHeading = styled.h1`
  /* Add any additional styles for the heading here */
`;

export const StyledCaseContent = styled.div`
  /* Add any additional styles for the case content here */
`;

export const TextLink = styled.a.attrs((color, size, hoverColor) => ({}))`
color: ${props => props.color ? props.color : darkGrey};
font-size: 24px;
text-decoration: none;


transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out, color 0.295s ease-in-out ;
z-index: 3;

&:hover {

color: ${props => props.hoverColor ? props.hoverColor : lightGrey};
  
 

}
`;



