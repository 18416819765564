// StrapiRichText.js
import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { lightGrey } from "../collections/SdColors";
const StyledMarkdownContent = styled.div`
  font-family: "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: ${lightGrey};

  /* Add more styles as needed */
`;

const StrapiRichText = ({ content }) => {
  return (
    <StyledMarkdownContent>
      <ReactMarkdown>{content}</ReactMarkdown>
    </StyledMarkdownContent>
  );
};

StrapiRichText.propTypes = {
  content: PropTypes.string.isRequired,
};

export default StrapiRichText;
