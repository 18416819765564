import React from "react";
import styled, { keyframes } from "styled-components";
import { darkGrey, lightGrey } from "../collections/SdColors";

// Define a keyframe animation
const growAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const BoxHolderWrapper = styled.div`
  position: relative;
  top: ${(props) => props.top || "0px"};
  width: ${(props) => props.width || "90%"};
  height: ${(props) => props.height || "auto"};
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${growAnimation} 3s ease-out; // Set the animation on the wrapper
  background-color: ${lightGrey}; // Add a background color for the box
  border-radius: 8px; // Add border-radius for rounded corners
`;

const TextStyled = styled.p`
  font-size: ${(props) =>
    props.fontSize || "auto"}; // Set the font size for the text
  color: ${darkGrey}; // Set the text color
  text-align: center; // Center-align the text
  font-weight: ${(props) => props.fontWeight || "300"}; // Make the font bold
`;

const GrowingBox = ({
  text,
  width,
  height,
  style,
  top,
  key,
  fontSize,
  fontWeight,
}) => {
  return (
    <BoxHolderWrapper width={width} height={height} style={style} top={top}>
      <TextStyled key={key} fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </TextStyled>
    </BoxHolderWrapper>
  );
};

export default GrowingBox;
