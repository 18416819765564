import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  StyledContentHolderContainer,
  StyledHeading,
} from "./styled/StyledCompGlobal";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./NavBar";
import PageHome from "./pages/PageHome";
import PagePortfolioOw from "./pages/PagePortfolioOw";
import PageAbout from "./pages/PageAbout";
import PageImprint from "./pages/PageImprint";
import PagePortfolioOwMobile from "./pages/mobile/PagePortfolioOwMobile";

const StyledCaseContent = styled.div`
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  height: 100% !important;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
`;

const StyledContent = styled.div`
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const ContentHolder = ({ caseValue }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let content;

  switch (caseValue) {
    case "portfol":
      content = (
        <StyledCaseContent key="portfol" isActive={caseValue === "portfol"}>
          {isSmallScreen ? <PagePortfolioOwMobile /> : <PagePortfolioOw />}
          {/* Add more styled components as needed */}
        </StyledCaseContent>
      );
      break;
    case "about":
      content = (
        <StyledCaseContent key="about" isActive={caseValue === "about"}>
          <PageAbout />
        </StyledCaseContent>
      );
      break;
    case "imprint":
      content = (
        <StyledCaseContent key="imprint" isActive={caseValue === "imprint"}>
          <PageImprint />
          {/* Add more styled components as needed */}
        </StyledCaseContent>
      );
      break;
    default:
      content = (
        <StyledCaseContent key="portfol" isActive={caseValue === "portfol"}>
          {isSmallScreen ? (
            <div>
              <Navbar />
            </div>
          ) : (
            <PagePortfolioOw />
          )}
          {/* Add more styled components as needed */}
        </StyledCaseContent>
      );
  }

  return (
    <StyledContentHolderContainer>
      <div>
        <Navbar />
      </div>
      {content}
    </StyledContentHolderContainer>
  );
};

export default ContentHolder;
