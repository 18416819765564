import React from "react";
import styled from "styled-components";
import { darkGrey, lightGrey } from "../../collections/SdColors";

const ImageListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  justify-content: center;
  border-bottom: 2.5px solid ${lightGrey};

  padding: 8px;
  top: ${(props) => (props.top ? props.top : null)};
  bottom: ${(props) => (props.bottom ? props.bottom : null)};
  left: ${(props) => (props.left ? props.left : null)};
  right: ${(props) => (props.right ? props.right : null)};
  height: ${(props) => (props.height ? props.height : null)};
  background-color: ${darkGrey};
  z-index: 3;
`;

const ImageWrapper = styled.div`
  width: auto;
  height: 100%;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: 0.375s ease-in-out;

  &:hover {
    background-color: ${lightGrey};
    cursor: pointer;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: ${darkGrey};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 10; /* Set a higher z-index */
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;

  ${ImageWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const StackListMobile = ({
  imageUrls,
  top,
  left,
  right,
  bottom,
  width,
  height,
  tooltipTexts,
  linkUrls,
}) => {
  if (!imageUrls || !Array.isArray(imageUrls) || imageUrls.length === 0) {
    return (
      <ImageListContainer
        width={width}
        height={height}
        top={top}
        left={left}
        right={right}
        bottom={bottom}
        style={{ backgroundColor: "red" }}
      >
        No Image URLs provided!
      </ImageListContainer>
    );
  }

  const handleClick = (link) => {
    window.open(link, "_blank"); // Open link in a new tab
  };

  return (
    <ImageListContainer
      width={width}
      height={height}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      {imageUrls.map((item, index) => (
        <ImageWrapper
          key={index}
          totalImages={imageUrls.length}
          activeIndex={index}
          onClick={() =>
            linkUrls && linkUrls[index] && handleClick(linkUrls[index])
          }
        >
          {/* Check if the item is an object, and extract the logoUrl property */}
          {typeof item === "object" && item.logoUrl && (
            <>
              <Image
                src={item.logoUrl}
                alt={`Image ${index}`}
                totalImages={imageUrls.length}
              />
              {tooltipTexts && tooltipTexts[index] && (
                <Tooltip>{tooltipTexts[index]}</Tooltip>
              )}
            </>
          )}
          {/* If item is not an object, assume it's a direct URL */}
          {typeof item === "string" && (
            <>
              <Image
                src={item}
                alt={`Image ${index}`}
                totalImages={imageUrls.length}
              />
              {tooltipTexts && tooltipTexts[index] && (
                <Tooltip>{tooltipTexts[index]}</Tooltip>
              )}
            </>
          )}
        </ImageWrapper>
      ))}
    </ImageListContainer>
  );
};

export default StackListMobile;
