// Navbar.js

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { lightGrey } from "./collections/SdColors";

const NavbarWrapper = styled.nav`
  background-color: #333;
  overflow: hidden;
  border-radius: 8px 8px 0px 0px;
`;

const NavLink = styled(Link)`
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;

  &:hover {
    background-color: #ddd;
    color: black;
  }
`;

const NavLinkImprint = styled(Link)`
  float: right;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;

  &:hover {
    background-color: #ddd;
    color: black;
  }
`;

const Navbar = () => {
  return (
    <NavbarWrapper>
      <NavLink to="/">Portfolio</NavLink>
      <NavLink to="/about">Über mich</NavLink>
      <NavLinkImprint to="/imprint">Impressum</NavLinkImprint>
    </NavbarWrapper>
  );
};

export default Navbar;
