import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import ImageHolder from "../widgets/ImageHolder";
import { fetchImageData } from "../../strapi";

import {
  PhotoImg,
  ContentHolder,
  ContentPhoto,
  View,
  ContentName,
  ContentPos,
  NameText,
  ContentAboutText,
  ContentPhoneEmail,
  TextLink,
  AboutText,
} from "../styled/StyledAbout";
import { fetchAboutData } from "../../strapi";

const PageAbout = () => {
  const apiURLVertreter = `${API_URL}/api/vertreters?populate=*`;
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const DataAbout = await fetchAboutData(apiURLVertreter);

    setAboutData(DataAbout[0]);
    console.log(aboutData);
    setIsLoading(false);
  };

  return (
    <View>
      <ContentHolder>
        {!isLoading && (
          <ContentPhoto>
            <PhotoImg imageSrc={aboutData.imageURL} />
            <ContentName>
              <NameText>{aboutData.name}</NameText>
            </ContentName>
            <ContentPos>{aboutData.position}</ContentPos>
          </ContentPhoto>
        )}
        {!isLoading && (
          <ContentAboutText>
            <AboutText>{aboutData.aboutText}</AboutText>
            <ContentPhoneEmail>
              <TextLink href="mailto:r.eickelbaum@solutiondynamic.de">
                r.eickelbaum@solutiondynamic.de
              </TextLink>
            </ContentPhoneEmail>
          </ContentAboutText>
        )}
      </ContentHolder>
    </View>
  );
};

export default PageAbout;
