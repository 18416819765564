import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  darkGrey,
  lightGrey,
  accent,
  darkGreyAlt,
} from "../collections/SdColors";
import GrowingIcon from "./GrowingIcon";
import { Loader } from "../styled/StyledLoadingIndicator";
import GrowingBox from "./GrowingBox";
const flipAnimation = keyframes`
  from {
    transform: rotateY(0deg);
    transform: height(0%);
  }
  to {
    transform: rotateY(180deg);
    transform: height(100%);
  }
`;

const CardWrapper = styled.div`
  width: 280px;
  height: 380px;
  perspective: 1000px;
  transition: transform 0.6s;
  border-radius: 8px;
`;

const CardInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transform-style: preserve-3d;
  transition: transform 1s;
  transform: ${(props) =>
    props.isFlipped ? "rotateY(180deg); " : "rotateY(0deg)"};
  animation: ${(props) => props.isFlipped && flipAnimation} 0.295s;
`;

const CardFrontBack = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${lightGrey};

  border-radius: 8px;
`;

const CardFront = styled(CardFrontBack)`
  background-color: ${darkGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.isContentVis ? "1" : "0")};
  background-color: ${darkGrey};
  border-radius: 8px;
`;
const CardBack = styled(CardFrontBack)`
  height: 100%;
  width: 98%;
  background-color: ${darkGrey};
  transform: rotateY(180deg);
  border-radius: 8px;
`;

const Card = ({ keyProp, iconSrc, title, text }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isContentVis, setIsContentVis] = useState(false);

  useEffect(() => {
    setIsFlipped(true);
    setTimeout(() => {
      setIsFlipped(false);
      setIsContentVis(true);
    }, 1350);
  }, [keyProp]);

  return (
    <CardWrapper>
      <CardInner isFlipped={isFlipped}>
        <CardFront>
          <CardContent isContentVis={isContentVis}>
            <GrowingIcon
              src={iconSrc}
              width={"55px"}
              height={"55px"}
              top={"10%"}
            />
            <div style={{ height: "18%" }}></div>
            <GrowingBox text={title} fontSize={"18px"} fontWeight={"bold"} />
            <GrowingBox text={text} fontSize={"16px"} />
          </CardContent>
        </CardFront>

        <CardBack>
          <Loader />
        </CardBack>
      </CardInner>
    </CardWrapper>
  );
};

export default Card;
