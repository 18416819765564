import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { API_URL } from "../../../config";
import ImageSlider from "../../widgets/ImageSlider";
import { darkGrey, lightGrey, darkGreyAlt } from "../../collections/SdColors";
import CardMobile from "../../widgets/mobile/CardMobile";
import CarouselButtonsMobile from "../../widgets/mobile/CarouselButtonsMobile";
import LongTextFlipMobile from "../../widgets/mobile/LongTextFlipMobile";
import { TextLink } from "../../styled/StyledCompGlobal";
import StackListMobile from "../../widgets/mobile/StackListMobile";
import {
  CarouselContainerMobile,
  CardholderMobile,
  LongTextHolderMobile,
  WebsiteLinkHolderMobile,
  ImageContainerMobile,
} from "../../styled/StyledPortfolio";
import { MenuHolderMobile } from "../../styled/StyledCarouselMenu";
import { fetchPortOwData } from "../../../strapi";

const PagePortfolioOwMobile = () => {
  const apiUrlWebPort = `${API_URL}/api/website-portfolios?populate=*`;
  const apiUrlPluginsPort = `${API_URL}/api/plugin-portfolios?populate=*`;
  const apiUrlAppsPort = `${API_URL}/api/video-portfolios?populate=*`;
  const [dataWebPort, setWebPortData] = useState([]);
  const [dataPluginsPort, setPluginsPortData] = useState([]);
  const [DataAppsPort, setToolsPortData] = useState([]);
  const [visibleData, setVisibleData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeButtonIndexWeb, setActiveButtonIndexWeb] = useState(0);
  const [activeButtonIndexPlugins, setActiveButtonIndexPlugins] = useState();
  const [cardKey, setCardKey] = useState(Date.now());
  const [isHovered, setIsHovered] = useState(null);
  const [isRichTextHovered, setIsRichTextHovered] = useState(true);

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [parentIsFlipped, setParentIsFlipped] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Use the apiUrl from the props or any other source
    const DataWebPort = await fetchPortOwData(apiUrlWebPort);
    const DataPluginsPort = await fetchPortOwData(apiUrlPluginsPort);
    const DataAppsPort = await fetchPortOwData(apiUrlAppsPort);

    setWebPortData(DataWebPort);
    setPluginsPortData(DataPluginsPort);
    setToolsPortData(DataAppsPort);

    if (DataWebPort.length > 0) {
      setVisibleData(DataWebPort[0]);
    }

    setIsLoading(false);
  };

  const handleButtonClickWeb = (item, index) => {
    setVisibleData(item);
    setActiveIndex(0);
    setActiveButtonIndexWeb(index);
    setActiveButtonIndexPlugins();
    setCardKey(Date.now());
    console.log(parentIsFlipped);
  };

  const handleButtonClickPlugins = (item, index) => {
    setVisibleData(item);
    setActiveIndex(0);
    setActiveButtonIndexPlugins(index);
    setActiveButtonIndexWeb();
    setCardKey(Date.now());
    console.log(parentIsFlipped);
  };

  return (
    <CarouselContainerMobile>
      <MenuHolderMobile>
        <CarouselButtonsMobile
          data={dataWebPort}
          handleButtonClick={handleButtonClickWeb}
          activeButtonIndex={activeButtonIndexWeb}
          setIsHovered={setIsHovered}
          title="Webseiten"
        />
        <CarouselButtonsMobile
          data={dataPluginsPort}
          handleButtonClick={handleButtonClickPlugins}
          activeButtonIndex={activeButtonIndexPlugins}
          setIsHovered={setIsHovered}
          title="Apps"
        />
        <CarouselButtonsMobile
          data={dataPluginsPort}
          handleButtonClick={handleButtonClickPlugins}
          activeButtonIndex={activeButtonIndexPlugins}
          setIsHovered={setIsHovered}
          title="Wordpress Plugins"
        />
      </MenuHolderMobile>

      <CardholderMobile>
        {!isLoading && (
          <CardMobile
            key={cardKey}
            iconSrc={visibleData.iconUrl}
            title={visibleData.name}
            text={visibleData.title}
          />
        )}
      </CardholderMobile>

      <ImageContainerMobile>
        {visibleData && (
          <ImageSlider
            gallery={visibleData.gallery}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </ImageContainerMobile>
      {!isLoading && (
        <StackListMobile
          imageUrls={visibleData.stackIcons}
          tooltipTexts={visibleData.stackIcons.map((icon) => icon.name)}
          linkUrls={visibleData.stackIcons.map((icon) => icon.link)}
          width={"75%"}
          height={"50px"}
          bottom={"0%"}
          left={"0%"}
        />
      )}
      <LongTextHolderMobile isRichTextHovered={isRichTextHovered}>
        {!isLoading && (
          <LongTextFlipMobile
            isFlippedProp={isRichTextHovered}
            key={cardKey}
            text={
              isRichTextHovered
                ? visibleData.desc
                : "Weitere Informationen über dieses Projekt..."
            }
            onMouseEnter={() => {
              setIsRichTextHovered(true);
              console.log("Hovered");
            }}
            onMouseLeave={() => {
              const wasHovered = isRichTextHovered;
              setIsRichTextHovered(true);
              if (!wasHovered) {
                window.scrollTo({ top: 0, behavior: "smooth" });
                console.log("Not Hovered");
              }
            }}
          />
        )}
      </LongTextHolderMobile>
      {!isLoading && (
        <WebsiteLinkHolderMobile>
          <TextLink
            color={lightGrey}
            hoverColor={darkGrey}
            href={visibleData.websiteURL}
            target="_blank"
          >
            Webseite Besuchen
          </TextLink>
        </WebsiteLinkHolderMobile>
      )}
    </CarouselContainerMobile>
  );
};

export default PagePortfolioOwMobile;
