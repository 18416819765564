import React from "react";
import {
  Div,
  ButtonContainer,
  CarouselButton,
  Thumbnail,
  TitleBox,
  TitleText,
  MenuText,
} from "../styled/StyledCarouselMenu";

const CarouselButtons = ({
  data,
  handleButtonClick,
  activeButtonIndex,
  setIsHovered,
  title,
}) => {
  return (
    <Div>
      <TitleBox>
        <TitleText>{title}</TitleText>
      </TitleBox>
      <ButtonContainer>
        {data.map((item, index) => (
          <CarouselButton
            key={item.id}
            onClick={() => handleButtonClick(item, index)}
            isActive={index === activeButtonIndex}
            onMouseEnter={() => setIsHovered(index)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <Thumbnail
              src={item.thumbnailUrl}
              alt={`Thumbnail for ${item.name}`}
            />
            <MenuText
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(null)}
              isActive={index === activeButtonIndex}
            >
              {item.name}
            </MenuText>
          </CarouselButton>
        ))}
      </ButtonContainer>
    </Div>
  );
};

export default CarouselButtons;
