import styled from "styled-components";
import {
  darkGrey,
  lightGrey,
  accent,
  linkColor,
} from "../collections/SdColors";

export const View = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${darkGrey};
`;

export const ContentHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
  width: 80%;
  height: 75%;
  background-color: ${darkGrey};
`;

export const ContentAboutText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Add this line */
  align-items: center; /* Add this line */
  width: auto;
  height: auto;
  border-radius: 8px;
  background-color: ${darkGrey};
  overflow: auto;
`;

export const AboutText = styled.a`
  color: ${lightGrey};
  font-size: 24px;
  text-decoration: none;
  padding: 8px;
  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;
`;

export const LegalText = styled.a`
  color: ${lightGrey};
  font-size: 18px;
  text-decoration: none;
  padding: 8px;
  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;
`;

export const StyledLink = styled.a`
  color: ${linkColor};
  /* Add other styles as needed */
`;

export const TextLink = styled.a.attrs((color, size, hoverColor) => ({}))`
  color: ${(props) => (props.color ? props.color : linkColor)};
  font-size: 24px;
  text-decoration: none;

  transition: height 0.295s ease-in-out, background-color 0.295s ease-in-out,
    color 0.295s ease-in-out;
  z-index: 3;

  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : lightGrey)};
  }
`;
