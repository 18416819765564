import React from "react";
import {
  DivMobile,
  ButtonContainerMobile,
  ThumbnailMobile,
  TitleBoxMobile,
  TitleText,
  CarouselButtonMobile,
} from "../../styled/StyledCarouselMenu";

const CarouselButtonsMobile = ({
  data,
  handleButtonClick,
  activeButtonIndex,
  setIsHovered,
  title,
}) => {
  return (
    <DivMobile>
      <TitleBoxMobile>
        <TitleText>{title}</TitleText>
      </TitleBoxMobile>
      <ButtonContainerMobile>
        {data.map((item, index) => (
          <CarouselButtonMobile
            key={item.id}
            onClick={() => handleButtonClick(item, index)}
            isActive={index === activeButtonIndex}
            onMouseEnter={() => setIsHovered(index)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <ThumbnailMobile
              src={item.thumbnailUrl}
              alt={`Thumbnail for ${item.name}`}
            />
          </CarouselButtonMobile>
        ))}
      </ButtonContainerMobile>
    </DivMobile>
  );
};

export default CarouselButtonsMobile;
