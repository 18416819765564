import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import { fetchImprintData } from "../../strapi";
import {
  ContentHolder,
  View,
  ContentAboutText,
  AboutText,
  StyledLink,
  LegalText,
  TextLink,
} from "../styled/StyledImprint";
import { lightGrey, linkColor } from "../collections/SdColors";

const PageImprint = () => {
  const apiURLImprint = `${API_URL}/api/imprint?populate=*`;
  const [isLoading, setIsLoading] = useState(true);
  const [imprintData, setImprintData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const imprintData = await fetchImprintData(apiURLImprint);
    setImprintData(imprintData);
    setIsLoading(false);
  };

  return (
    <View>
      <ContentHolder>
        {!isLoading && (
          <ContentAboutText>
            <AboutText>{imprintData.personalName}</AboutText>
            <TextLink href={`mailto:${imprintData.email}`}>
              {imprintData.email}
            </TextLink>
            <TextLink></TextLink>
            <AboutText>{imprintData.street}</AboutText>
            <AboutText>{`${imprintData.postCode}, ${imprintData.state}`}</AboutText>
            <LegalText>
              {
                "Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für Verbraucher: "
              }
              <StyledLink
                href="https://ec.europa.eu/consumers/odr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr/
              </StyledLink>
              {
                " Wir sind nicht bereit und nicht verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen."
              }
            </LegalText>
          </ContentAboutText>
        )}
      </ContentHolder>
    </View>
  );
};

export default PageImprint;
