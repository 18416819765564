import React from "react";
import {
  Loader,
  LoadingIndicatorContainer,
  LogoText,
} from "../styled/StyledLoadingIndicator";

const LoadingIndicator = () => {
  return (
    <LoadingIndicatorContainer>
      <LogoText>Solution Dynamic</LogoText>
      <Loader />
    </LoadingIndicatorContainer>
  );
};

export default LoadingIndicator;
