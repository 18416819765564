import styled from "styled-components";
import { darkGrey, lightGrey, accent } from "../collections/SdColors";

export const Div = styled.div`
  padding-bottom: 0%;
  width: 100%;
  height: auto;
  background-color: transparent;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
`;

export const TitleBox = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: auto;
  top: 12px;
  border-top: 1px solid ${lightGrey};
  border-bottom: 1px solid ${lightGrey};
  border-right: 1px solid ${lightGrey};
  border-radius: 0 8px 8px 0;
  background-color: ${darkGrey};
`;

export const MenuText = styled.a`
  color: ${darkGrey};
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.isActive ? "100%" : "100%")};
  height: ${(props) => (props.isActive ? "100%" : "100%")};
  transition: width 0.3s, height 0.3s; /* Add a transition for smooth animation */

  &:hover {
    width: 100%;
    height: 100%;
  }
`;

export const TitleText = styled.div`
  color: ${lightGrey};
  padding: 5px;
`;

export const MenuHolder = styled.div`
  width: 14.5%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: transparent;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
`;

export const ButtonContainer = styled.div`
  position: relative;

  top: 12px;
  height: 65%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
`;

export const CarouselButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 12px;
  margin-left: 0px;
  width: ${(props) => (props.isActive ? "95%" : "90%")};
  height: ${(props) => (props.isActive ? "110%" : "100%")};
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  background-color: ${(props) => (props.isActive ? lightGrey : lightGrey)};
  color: ${(props) => (props.isActive ? darkGrey : darkGrey)};
  border: none;
  border-radius: 0 8px 8px 0;
  z-index: 4 !important;
  transition: background-color 0.295s ease-in-out, color 0.295s ease-in-out,
    width 0.295s ease-in-out, opacity 0.295s ease-in-out,
    height 0.295s ease-in-out;
  &:hover {
    background-color: ${lightGrey};
    width: 100%;
    height: 100%;
    color: ${darkGrey};
    opacity: 1;
  }

  &:hover ~ ${MenuText} {
    width: 100%;
    height: 100%;
  }
`;

export const Thumbnail = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ThumbnailMobile = styled.img`
  width: 300px;
  height: 100%;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const DivMobile = styled.div`
  width: auto;
  height: 100%;
  background-color: transrarent;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
`;
export const CarouselButtonMobile = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px;
  margin-left: 12px;
  width: ${(props) => (props.isActive ? "100%" : "100%")};
  height: ${(props) => (props.isActive ? "170px" : "170px")};
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  background-color: ${(props) => (props.isActive ? lightGrey : lightGrey)};
  color: ${(props) => (props.isActive ? darkGrey : darkGrey)};
  border: none;
  border-radius: 0px 0px 8px 8px;
  z-index: 4 !important;
  transition: background-color 0.295s ease-in-out, color 0.295s ease-in-out,
    width 0.295s ease-in-out, opacity 0.295s ease-in-out,
    height 0.295s ease-in-out;
`;
export const ButtonContainerMobile = styled.div`
  position: relative;

  left: 0px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 3;
  background-color: transparent;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
`;
export const MenuHolderMobile = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  background-color: transparent;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
`;

export const TitleBoxMobile = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-left: 10px;
  border-left: 12px solid ${accent};
  border-radius: 8px 8px 8px 8px;
  background-color: ${darkGrey};
`;
